'use strict';

angular.module('enervexSalesappApp').controller('FancalcJobsCtrl', function($scope, $stateParams, AccountLocation, Account, FancalcJob, Flash, _, Property) {
	$scope.allJobs = [];
	$scope.jobs = [];
	$scope.offset = 0;
	$scope.limit = 100;
	$scope.accountId = $stateParams.accountId;
	$scope.accountLocationId = $stateParams.accountLocationId;
	$scope.configs = Property.configs();

	function fetchAccountLocations() {
		var params = {
			accountId: $stateParams.accountId
		}
		if ($stateParams.accountLocationId) {
			params._id = $stateParams.accountLocationId;
		}
		console.log("querying "+JSON.stringify(params))
		AccountLocation.query(params).$promise.then(function(res){
			$scope.accountLocations = res;
			$scope.owncids = _.map(res, function(loc){
				return loc.legacyId
			})
			$scope.owncids = _.compact($scope.owncids);
			fetchJobs();
		});

	}
	$scope.getJobFilter = function(){
		var regex = null;
		if ($scope.filterValue != ""){
			var regex = new RegExp($scope.filterValue, "i");
			$scope.jobs = _.filter($scope.allJobs, function(job){
				return regex.test(job["Quote No"]) || regex.test(job["Job Name"])
			})
		} else {
			$scope.jobs = $scope.allJobs;
		}
	}
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	fetchAccountLocations();

	function fetchJobs() {
		if (!$scope.owncids || $scope.owncids.length == 0) {
			$scope.jobs = [];
			return;
		}
		var created = moment().subtract(parseInt($scope.configs.fancalcMaxMonths), 'months').toISOString()
		FancalcJob.query({
			offset:$scope.offset,
			limit: $scope.limit,
			accountId: $stateParams.accountId,
			// owncid: $scope.owncids.join(','),
			orderby: '-created',
			// revised: "2015-12-31T14:13:46.810Z"
			created: created
		}).$promise.then(function(jobs){
			if (jobs.length > 0) {
				$scope.allJobs = $scope.allJobs.concat(jobs);
				$scope.offset = $scope.offset + $scope.limit;
				fetchJobs();
			} else {
				$scope.jobs = $scope.allJobs;
			}
		});
	}
	$scope.sync = function(job){
		FancalcJob.syncAll({
			id: job.ID,
			accountId: $stateParams.accountId
		}).$promise.then(function(response) {
			console.log('success', response)
			
			$scope.success = true;
			var url = "/accounts/"+response.job.account +"/jobs/"+response.job._id;
			var message = '<strong>Success!</strong> You successfully synced ' + job['Job Name'] + ', <a href="'+url+ '">check it out</a>.';
			Flash.create('success', message, 0, {}, true);
		}).catch(function(err){
			console.log('err', err)
			
			$scope.errors = {};
			$scope.errors.other = err.message;
			var message = err.message || "There as an error syncing the fancalc job";
			Flash.create('danger',  '<strong>Error!</strong> '+message, 0, {}, true);
		})
	}
});
